<template>
  <b-card bg-variant="light"
          header="User activities"
          header-bg-variant="dark"
          header-text-variant="white">
    <b-tabs no-body>
      <b-tab active
             class="ma-1 mt-4"
             title="Feedbacks">
        <feedback_list :elevated="true"
                       :load_data_url="feedbacks_url"
                       :prop_feedback_fields="feedback_fields"
                       :prop_fields="feedback_list_fields"></feedback_list>
      </b-tab>
      <b-tab class="ma-1 mt-4"
             title="Experiment reports">
        <experiment_list :elevated="false"
                         :load_data_url="experiments_url"
                         :prop_fields="experiment_list_fields"
                         :show_zip_button="true"></experiment_list>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
  import feedback_list from '@/components/proposal/feedbacks/feedback_list';
  import experiment_list from '@/components/proposal/experiment_report/experiment_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'feedbacks_reports',
    components: {
      feedback_list,
      experiment_list,
    },
    data() {
      return {
        feedbacks_url: '/documents/feedbacks',
        experiments_url: '/documents/experiment_reports/synchrotron',
        feedback_list_fields: [
          ordinalNumber,
          {
            key: 'document_specification.document_id',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'title',
            label: 'Title',
          },
          {
            key: 'author',
            label: 'Author',
          },
          {
            key: 'created_at',
            label: 'Creation date',
          },
          { key: 'actions' },
        ],
        feedback_fields: [
          {
            key: 'author',
            label: 'Author',
          },
          {
            key: 'states.date',
            label: 'Submitted at',
          },
          { key: 'actions' },
        ],
        experiment_list_fields: [
          ordinalNumber,
          {
            key: 'document_specification.document_id',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'title',
            label: 'Title',
          },
          {
            key: 'author',
            label: 'Author',
            tdClass: 'preLine',
          },
          {
            key: 'general.end_station',
            label: 'End station',
          },
          {
            key: 'states.date',
            label: 'Proposal submission date',
          },
          {
            key: 'experiment_report.states.name',
            label: 'Report status',
          },
          {
            key: 'experiment_report.states.date',
            label: 'Report submission date',
          },
          {
            key: 'actions',
            label: 'Actions',
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }
</style>
